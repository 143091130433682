<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col v-if="!windowBreakpoint" cols="8" class="col-class">
        <v-sheet
          class="col1-sheet py-8 px-9"
          :style="{ backgroundColor: color.primaryVariant }"
        >
          <div>
            <v-img
              src="@/assets/images/weChamber/logo-WC-main-nav-drawer-white.svg"
              width="160px"
              height="auto"
            ></v-img>
          </div>
          <div class="col1-sheet-content">
            <p>
              Elige el <span>perfil</span><br />
              al que deseas acceder.
            </p>
          </div>
          <div class="col1-sheet-footer">
            <p>
              © Copyright 2023, Plataforma Digital WeChamber. All Rights
              Reserved.
            </p>
          </div>
        </v-sheet>
      </v-col>
      <v-col :cols="windowBreakpoint ? 12 : 4" class="col-class">
        <v-sheet
          class="col2-sheet py-8 px-9"
          :style="{ backgroundColor: '#f9fafc' }"
        >
          <v-row
            no-gutter
            class="d-flex fill-height align-start justify-center"
          >
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                :color="color.primaryVariant"
                class="white--text mr-4"
                large
                :class="buttonClass"
                @click="_logout()"
                >Cerrar Sesión</v-btn
              >
              <v-btn large :class="buttonClass" @click="_deleteSwitch">
                {{ isDeleting ? "Volver" : "Eliminar Perfiles" }}
              </v-btn>
            </v-col>
            <v-col :cols="windowBreakpoint ? 12 : 9">
              <div class="col2-sheet-content-profiles-title text-center mb-8">
                <p v-if="changeMode">Membresías disponibles</p>
                <p v-else>Agregar nueva membresía</p>
              </div>
              <v-row
                class="col2-sheet-content-profiles"
                no-gutters
                align="center"
                justify="center"
                v-if="changeMode"
              >
                <v-col
                  class="pm-4"
                  v-for="(profile, j) in profiles"
                  :key="j"
                  xs="6"
                  sm="3"
                  md="3"
                  lg="6"
                  xl="5"
                >
                  <affiliation-card
                    @click="profileClickFunction(profile)"
                    :delete-mode="
                      deleteMembershipActive && profile.status !== 'ACTIVE'
                    "
                    :card-disabled="profile.status != 'ACTIVE'"
                    :box-type="'square'"
                    :card-name="profile.organization.company"
                    :membership="profile.status"
                    :card-image="profile.organization.profilePicture"
                  />
                </v-col>
                <!-- :class="windowBreakpoint ? 'ma-0' : 'ma-4'" -->
                <v-col class="pm-4" xs="6" sm="3" md="3" lg="6" xl="5">
                  <affiliation-card
                    @click="_addNewAffiliation"
                    :card-icon="'mdi-plus'"
                    :box-type="'square'"
                    :card-name="'Agregar nuevo'"
                  >
                  </affiliation-card>
                </v-col>
              </v-row>
              <v-row
                v-else
                no-gutters
                class="col2-sheet-content-profiles"
                justify="center"
              >
                <v-col
                  class="pm-4"
                  cols="10"
                  v-for="(item, i) in branches"
                  :key="i"
                >
                  <affiliation-card
                    @click="
                      item.membership != 'NONE'
                        ? _redirectToCheckout(item._id)
                        : _createNewMembership(item._id)
                    "
                    :membership="item.membership"
                    :affiliation-id="item._id"
                    :box-type="'rectangle'"
                    :card-image="item.profilePicture"
                    :card-name="item.company"
                  />
                </v-col>
              </v-row>
              <div v-if="!changeMode" class="pt-10">
                <v-btn
                  elevation="0"
                  @click="_addNewAffiliation"
                  class="rounded-pill text-capitalize font-weight-bold px-12"
                  :color="color.secondary"
                >
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";

import { colors } from "@/constants/colors.js";
import affiliationCard from "../../../components/memberDashboard/affiliationProfiles/affiliationCard.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    affiliationCard,
  },
  data() {
    return {
      isDeleting: false,
      color: colors,
      buttonClass: "text-capitalize font-weight-bold rounded-pill",
      branches: [],
      profiles: [],
      profilesId: {},
      deleteMembershipActive: false,
      selectNewAffiliation: true,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    windowBreakpoint() {
      return this.$vuetify.breakpoint.mobile;
    },
    changeMode() {
      return this.selectNewAffiliation;
    },
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapActions("membershipProfile", [
      "getAllMemberships",
      "getUserMemberships",
      "createMembership",
      "getMembershipById",
    ]),
    ...mapActions("user", ["realoadUserData"]),
    ...mapMutations("user", ["logout"]),
    async getMemberships() {
      if (this.user.validationStatus == "DESARROLLADOR") {
        console.log(this.user.validationStatus);
      }

      try {
        let response = await this.getAllMemberships();
        this.branches = response.branches;
        this.branches.forEach((e) => {
          e.membership = this.profilesId[e.id] || "NONE";
        });

        console.log(this.profilesId);
      } catch (error) {
        console.log(error.data);
      }
    },

    profileClickFunction(profile) {
      return this.deleteMembershipActive
        ? this._deleteMembership(profile)
        : profile.status == "ACTIVE"
        ? this._goToHomePage(profile)
        : this._redirectToCheckout(profile.organization.id);
    },

    async _deleteMembership(profile) {
      if (profile.status === "ACTIVE") {
        return;
      }

      try {
        await axios.delete(`/memberships/${profile._id}`);
        this.getUserMembershipList();
      } catch (error) {
        console.error("Error al eliminar la membresía:", error);
      }
    },

    async getUserMembershipList() {
      try {
        const response = await this.getUserMemberships();
        let deletedMemberships = JSON.parse(
          localStorage.getItem("deletedMemberships") || "[]"
        );
        this.profiles = response.memberships.memberships.filter(
          (profile) => !deletedMemberships.includes(profile.organization.id)
        );
        console.log(this.profiles);
        let ob = {};
        this.profiles.forEach((e) => {
          ob[e.organization.id] = e.status;
        });
        this.profilesId = ob;
      } catch (error) {
        console.log(error);
      }
    },

    _deleteSwitch() {
      this.deleteMembershipActive = !this.deleteMembershipActive;
      this.isDeleting = !this.isDeleting;
    },

    _logout() {
      this.logout();
      this.$router.push("/login");
    },
    _addNewAffiliation() {
      this.selectNewAffiliation = !this.selectNewAffiliation;
    },
    async _goToHomePage(profile) {
      localStorage.setItem("affiliationProfile", profile._id);
      await this.getMembershipById(localStorage.getItem("affiliationProfile"));
      this.$router.push({
        name: "Member Home",
      });
    },
    _redirectToCheckout(itemId) {
      this.$router.push({
        name: "Renew Membership",
        params: {
          setStep: 2,
          membershipId: this.user._id,
          affiliationId: itemId,
        },
      });
    },
    async _createNewMembership(itemId) {
      try {
        const response = await this.createMembership({ branch: itemId });

        if (response.status) {
          this.profiles.push(response.data);
          this.branches = this.branches.map((e) => {
            if (e.id == itemId) {
              return { ...e, membership: "PENDING" };
            }
            return e;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async _createNewMembershipDeveloper(itemId) {
      try {
        const response = await this.createMembership({ branch: itemId });

        if (response.status) {
          this.profiles.push(response.data);
          this.branches = this.branches.map((e) => {
            if (e.id == itemId) {
              return { ...e, membership: "ACTIVE" };
            }
            return e;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    localStorage.removeItem("affiliationProfile");
    await this.getUserMembershipList();
    await this.getMemberships();
  },
};
</script>
<style scoped>
.col-class {
  height: 100vh;
}

.col1-sheet {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.col1-sheet-content {
  padding-left: 2rem;
  font-size: 3.9rem;
  line-height: 4rem;
  color: #f9fafc;
  font-weight: bold;
}

.col1-sheet-content > p > span {
  color: #24b2da;
}

.col1-sheet-footer {
  color: #f9fafc;
  font-size: 0.7rem;
}

.col2-sheet {
  height: 100vh;
}

.col2-sheet-content-profiles {
  max-height: 60vh;
  overflow-y: auto;
}

.col2-sheet-content-profiles-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.col2-sheet-content-profiles::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.col2-sheet-content-profiles::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

/* Handle */
.col2-sheet-content-profiles::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
.col2-sheet-content-profiles::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
