<template>
  <div :class="boxType == 'square' && 'd-flex flex-column align-center'">
    <v-hover v-if="boxType == 'square'" v-slot="{ hover }">
      <v-card width="120" height="120" class=" card rounded-lg d-flex justify-center pointer" :elevation="hover ? 13 : 6"
        @click="_getCardFunction">

        <div v-if="!deleteMode" style="height: 120px; width:120px" class="d-flex align-center justify-center">
          <v-icon v-if="cardIcon != ''" size="80" :color="hover ? 'blue' : 'gray'">{{ cardIcon }}</v-icon>

          <v-img v-else contain class="ma-5" alt="affiliaton_logo"
            :src="cardImage != 'NOT_ASSIGNED' ? cardImage : defaultImage">
          </v-img>

          <v-icon v-if="membership == 'PENDING'" size="30" color="#ffb500" class="card-icon"
            aria-hidden="false">mdi-alert-circle</v-icon>
        </div>
        <div v-else class="d-flex align-center">
          <v-icon size="90" class="d-flex justify-center">mdi-delete</v-icon>
        </div>

      </v-card>
    </v-hover>
    <v-hover v-else-if="boxType == 'rectangle'" v-slot="{ hover }">
      <v-card class="pa-4 my-2 d-flex justify-start pointer" @click="_getCardFunction" :elevation="hover ? 13 : 6">
        <v-img class="flex-grow-0" contain height="50" width="50"
          :src="cardImage != 'NOT_ASSIGNED' ? cardImage : defaultImage"></v-img>
        <p class="flex-grow-1 d-flex align-center ma-0 pl-4">
          {{ cardName }}
        </p>
        <v-icon size="30" :color="status[membership].color" class="font-weight-bold">
          {{ status[membership].icon }}
        </v-icon>
      </v-card>
    </v-hover>
    <p v-if="boxType == 'square'" class="py-4 font-weight-bold text-center squareText">{{ cardName }}</p>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import logoWcMicrosite from '@/assets/images/weChamber/logo-WC-microsite.svg'
export default {
  props: {
    cardImage: {
      type: String,
      default: 'NOT_ASSIGNED'
    },
    cardName: {
      type: String,
      default: ''
    },
    boxType: {
      type: String,
      default: 'square'
    },
    affiliationId: {
      type: String,
    },
    cardIcon: {
      type: String,
      default: ''
    },
    membership: {
      type: String,
      default: ''
    },
    cardDisabled: {
      type: Boolean,
      default: false
    },
    deleteMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    defaultImage: logoWcMicrosite,
    status: {
      'NONE': { color: '#1b273e', icon: 'mdi-plus-circle-outline' },
      'PENDING': { color: '#1b273e', icon: 'mdi-arrow-right-thick' },
      'ACTIVE': { color: '#41bb85', icon: 'mdi-check-circle' }
    }
  }),
  methods: {
    _getCardFunction() {
      this.$emit('click')
    }
  },
  computed: {
    ...mapState("user", ["user"]),
  },
};
</script>
<style scoped>
.card {
  position: relative;
}

.card-icon {
  position: absolute;
  top: 4px;
  right: 4px;
}

.squareText {
  line-height: .6rem;
  word-break: break-word;
  font-size: .8rem;
}
</style>